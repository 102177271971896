<template>
    <div class="preOrder">
        <van-nav-bar
                title="点可"
                left-text=""
                right-text=""
                @click-left="goBack"
                @click-right=""
                left-arrow
        />

        <div class="van-address-item">
            <div class="van-cell van-cell--borderless">
                <div class="van-cell__value van-cell__value--alone van-address-item__value">
                    <div role="radio" class="van-radio" tabindex="0" aria-checked="true">
                        <div class="van-radio__icon van-radio__icon--round van-radio__icon--checked"
                             style="font-size: 18px;"><i class="van-badge__wrapper van-icon van-icon-success">
                            </i></div>
                        <span class="van-radio__label"><div class="van-address-item__name">张三 13000000000<span
                                class="van-tag van-tag--round van-tag--danger van-address-item__tag">默认
                            </span></div><div class="van-address-item__address">浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室</div></span>
                    </div>
                </div>
                <i class="van-badge__wrapper van-icon van-icon-edit van-address-item__edit"></i>
                </div></div>


        <van-card
                num="1"
                tag="特价"
                price="988.00"
                desc="可在设备告警的第一时间通知本地服务人员上门查看"
                title="设备告警托管服务(1年)"

                origin-price="9999.00">
            <template v-slot:thumb>
                <img class="thumb-img" src="../assets/img/logo.png"/>
            </template>
        </van-card>

        <van-submit-bar :price="99800" button-text="提交订单" @submit="onSubmit"/>

        <van-action-sheet v-model:show="show" title="支付">
            <div class="content">
                <!-- 密码输入框 -->
                <van-password-input
                        :value="pass"
                        :focused="show"
                        @focus="showKeyboard = true"
                />
                <!-- 数字键盘 -->
                <van-number-keyboard
                        v-model="pass"
                        :show="show"
                        @blur="showKeyboard = true"
                />

            </div>
        </van-action-sheet>
    </div>
</template>

<script>
    import { PasswordInput, NumberKeyboard } from 'vant'
    import { ActionSheet } from 'vant'
    import {Toast} from 'vant'
    import { ref, reactive,watch } from 'vue'
    import {AddressList} from 'vant'
    import {Card, Field, SubmitBar} from 'vant'
    import Tabbar from "../components/Tabbar"
    import NavBar from "../components/NavBar"
    import router from "../router";
    import axios from "axios";

    import {encryptXD, getTokenFromLocalStorage} from "../util/util";

    export default {
        name: "PreOrder",
        components: {Tabbar},
        data(){
            return {
                show: false,
                pass: '',
            }
        },
        watch:{
            pass:{
                handler(newVal,oldVal){
                    if (newVal.length==6) {
                        if (newVal == '123456') {
                            this.show = false
                            Toast.success("支付成功")

                            let token = getTokenFromLocalStorage(localStorage.getItem("currentUser"))
                            console.log(token)
                            axios({
                                url:'/dkv1/escrow/order',
                                method:'post',
                                params: {
                                    // serialNo: this.serialNo,
                                    // timestamp: new Date().getTime(),
                                    // deviceType: 'fall'
                                },
                                headers: {'Authorization': 'Bearer ' + token}
                            }).then(resp => {
                                if (resp.data.code == 0){
                                    Toast.success("托管订单创建成功")
                                    router.push('/escrow')
                                }else{
                                    Toast.fail("托管订单创建失败："+resp.data.msg)
                                }
                                this.showAddPopup=false
                            }).catch(error => {
                                let statusCode = error.response.status
                                if (statusCode>=400 && statusCode<500){
                                    router.push('/login')
                                }else if (statusCode>=500){
                                    Toast.fail("服务器错误")
                                }
                                this.showAddPopup=false
                                this.ws.close(1)
                            })
                        } else {
                            Toast.fail("密码错误")
                        }
                    }
                },
                immediate:true,
            }
        },
        methods: {
            goBack() {
                window.history.go(-1)
            },
            onSubmit(){
                this.show = true
            }
        }
    }
</script>

<style scoped>
    .content {
        padding: 16px 16px 250px;
    }

    .van-address-item {
        padding: 12px;
    }

    .thumb-img {
        width: 100%;
    }
</style>
