<template>
    <view class="content">
        <view>二维码数据是：{{result}}</view>
        <button class="btn" type="primary" @tap="open">自定义二维码界面</button>
    </view>
</template>

<script>
    export default {
        name: 'Scan',
        data() {
            return {
                text: '测试',
                type: 'scan-listener',
                result: '' //获取到的二维码内容，根据自己需求处理结果

            }
        },
        methods: {
            handClick(res){
                this.result = res;
            },
            open() {
                uni.navigateTo({
                    url: './test?text=' + this.text + '&type=' + this.type,
                    success: res => {},
                    fail: () => {},
                    complete: () => {}
                });
            }
        },
        onReady() {
            uni.$on('handClick',this.handClick)
            // #ifdef APP-PLUS
            // #endif
        },
        onLoad() {
            // #ifdef APP-PLUS
            this.$eventHub.$on(this.type, function(data) {
                console.log(data);
                this.text = data;
            });
            // #endif
        },
        onBackPress() {
            // #ifdef APP-PLUS
            plus.screen.lockOrientation('portrait-primary')
            // #endif
        }
    }
</script>

<style scoped>
    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .btn {
        width: 50%;
    }
</style>