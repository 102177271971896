import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import Blogs from "../views/Blogs";
// import Vlogs from "../views/Vlogs";
import Setting from "../views/Setting";
// import Search from "../views/Search";
import Test from "../views/Test";
import Scan from "../views/Scan";
import Login from "../views/Login";
import Event from "../views/Event";
import StatChart from "../components/StatChart";
import HelloWorld from "../components/HelloWorld";
import Genus from "../views/Genus";
import PreOrder from "../views/PreOrder";
import Escrow from "../views/Escrow";
import TestAxios from "../views/TestAxios";
import Species from "../views/Species";
import SpeciesDetail from "../views/SpeciesDetail";


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Genus
  },
  {
    path: '/event',
    name: 'Event',
    component: Event
  },
  {
    path: '/scan',
    name: 'Scan',
    component: Scan
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/chart',
    name: StatChart,
    component: StatChart
  },
  {
    path: '/genus',
    name: 'Genus',
    component: Genus
  },
  {
    path: '/species',
    name: 'Species',
    component: Species
  },
  {
    path: '/speciesDetail',
    name: 'SpeciesDetail',
    component: SpeciesDetail
  },
  {
    path: '/preOrder',
    name: 'PreOrder',
    component: PreOrder
  },
  {
    path: '/escrow',
    name: 'Escrow',
    component: Escrow
  },
  {
    path: '/hello',
    name: 'Hello',
    component: HelloWorld
  },
  {
    path: '/testAxios',
    name: 'testAxios',
    component: TestAxios
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
