<template>
  <div id="echarts_box">
    <div id="chart1"></div>
  </div>
</template>

<script>
  import axios from "axios";
  var echarts = require('echarts');
  // import * as echarts  from 'echarts'
  import {getTokenFromLocalStorage} from "../util/util";
  import router from "../router";
  export default {
      name: 'StatChart',
      data () {
        return {
          dateStart:"123",
          dateStop:"234",
          name:"3245",
          cnt:1
        }
      },
      created () {
      },
      // DOM 渲染完成触发
      mounted() {

//         // 基于准备好的dom，初始化echarts实例
//         var myChart = echarts.init(document.getElementById('main'));
// // 绘制图表
//         myChart.setOption({
//           title: {
//             text: 'ECharts 入门示例'
//           },
//           tooltip: {},
//           xAxis: {
//             data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
//           },
//           yAxis: {},
//           series: [{
//             name: '销量',
//             type: 'bar',
//             data: [5, 20, 36, 10, 10, 20]
//           }]
//         });


        // 1. 基于准备好的dom，初始化echarts实例
        var chart1 = echarts.init(document.getElementById('chart1'))

        // 2. 发起请求，获取数据
        // const {data: res} = await this.$http.get('/dkv1/t/chart/data?chart=1')
        // if (res.meta.status !== 200) {
        //   return this.$message.error('获取折线图数据失败！')
        // }

        // 远程数据1,写死的
        let token = getTokenFromLocalStorage(localStorage.getItem("currentUser"))
        axios({
          url: '/dkv1/chart/event/fall',
          method: 'get',
          params: {"chart":1},
          headers: {'Authorization': 'Bearer ' + token}
        }).then(function (resp) {
          var data = resp.data
          var opt = {
            title: {
              text: '跌倒'
            },
            tooltip: {},
            legend: {
              data: data['legend']
            },
            xAxis: {
              data: data['xAxis']
            },
            yAxis: {},
            series: []
          }

          var series = new Array()
          for(var i=0;i<data['legend'].length;i++){
            var o = new Object()
            o.name = data['legend'][i]
            o.type='line'
            o.data = data['series'][o.name]
            o.smooth = true
            series[i]=o
          }
          opt['series'] = series
          chart1.setOption(opt)
        }).catch(error => {
          let statusCode = error.response.status
          if (statusCode>=400 && statusCode<500){
            router.push('/login')
          }else if (statusCode>=500){
            Toast.fail("服务器错误")
          }
          this.showAddPopup=false
        })


      }
    }
</script>

<style scoped>
  #echarts_box{
    width: 98%;
    height: 98%;

  }
  #chart1{
    width: 100vw;
    height: 70vw;
  }
  /*#main {*/
  /*  width: 100vw;*/
  /*  height: 70vw;*/
  /*}*/

</style>
