<template>

</template>

<script>
    import request from '../util/axios'
    export default {
        name: "TestAxios",
        mounted() {
            request({
                url: 'clog/genus/search',
                method: 'get',
                params: {searchKey:'',
                    firstChar:'',
                    page:11,
                    size:20}
            }).then(resp => {
                console.log(resp)
            })


        }
    }
</script>

<style scoped>

</style>
