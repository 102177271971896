import {validatenull} from './validate'
// import request from '@/router/axios'
import * as CryptoJS from'crypto-js'
import axios from "axios";
import router from "../router";

// 表单序列化
export const serialize = data => {
  let list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}
export const getObjType = obj => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close
  var o1 = obj1 instanceof Object
  var o2 = obj2 instanceof Object
  if (!o1 || !o2) { /*  判断不是对象  */
    return obj1 === obj2
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object
    var t2 = obj2[attr] instanceof Object
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr])
    } else if (obj1[attr] !== obj2[attr]) {
      return false
    }
  }
  return true
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
  if (status) {
    document.body.className = document.body.className + ' grayMode'
  } else {
    document.body.className = document.body.className.replace(' grayMode', '')
  }
}
/**
 * 设置主题
 */
export const setTheme = (name) => {
  document.body.className = name
}

/**
 *加密处理
 */
export const encryption = (params) => {
  let {
    data,
    type,
    param,
    key
  } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(
        data,
        key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding
        })
      result[ele] = encrypted.toString()
    })
  }
  return result
}


/**
 * 加密密码 夏栋
 */
export const encryptXD = (passwordMing) => {
  let key = 'dkdkdkdkdkdkdkdk'
  let ekey = CryptoJS.enc.Latin1.parse(key)
  let iv = ekey
  // 加密
  let encrypted = CryptoJS.AES.encrypt(
      passwordMing,
      ekey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
  return encrypted.toString()
}

/**
 * 获取localStorage TOKEN 夏栋
 */
export const getTokenFromLocalStorage = (username) => {
  // 尝试获取存入的token
  var storageToken = localStorage.getItem('token_' + username)
  if (storageToken == null) {
    router.push('/login')
  } else {
    storageToken = JSON.parse(storageToken)
    let expire_time = storageToken['expire']
    let now = new Date().getTime()

    if ((now - expire_time) <= 60 * 2) {
      localStorage.removeItem('token_' + username)
      router.push('/login')
    } else {
      return storageToken['access_token']
    }


  }
}
/**
 * 获取TOKEN 夏栋
 */
export const getToken = async (username, password) => {
  // 尝试获取存入的token
  var storageToken = localStorage.getItem('token_' + username)
  if (storageToken == null) {
    return await getTokenNow(username, password)
  } else {
    storageToken = JSON.parse(storageToken)
    let expire_time = storageToken['expire']
    let now = new Date().getTime()

    if ((now - expire_time) <= 60 * 2) {
      var refresh_token = storageToken['refresh_token']
      return await refreshTokenXD(username, refresh_token)
    } else {
      return storageToken['access_token']
    }
  }
}

/**
 * 重新获取token
 * @param username
 * @param password
 * @returns {Promise<*>}
 */
export const getTokenNow = async (username, password) => {
  var token;
  await axios({
    url: '/auth/oauth/token',
    method: 'post',
    params: {
      'username': username,
      'password': encryptXD(password),
      'grant_type': 'password',
      'scope': 'server'
    },
    headers: {
      isToken: false,
      'Authorization': 'Basic dGVzdDp0ZXN0'
    }
  }).then(response => {
    // console.info(response.data)
    var data = response.data
    let now = new Date().getTime()
    let expire_time = now + parseInt(data['expires_in'])
    data['expire_time'] = expire_time

    localStorage.setItem('token_' + username, JSON.stringify(data))
    localStorage.setItem('currentUser', username)
    token = data['access_token']
  }).catch(function (error) { // 请求失败处理
    console.log(error)
  })
  return token
}
/**
 * 刷新token
 * @param username
 * @param refresh_token
 * @returns {Promise<void>}
 */
export const refreshTokenXD = async (username,refresh_token) => {
  await axios({
    url:'/auth/oauth/token',
    method:'post',
    params:{
      'grant_type':'refresh_token',
      'scope':'server'
    },
    headers: {
      isToken:false,
      'Authorization': 'Basic dGVzdDp0ZXN0'
    }
  }).then(response => {
    this.info = response
    console.info(response.data)
    var data = response.data
    let now = new Date().getTime()
    let expire_time = now + parseInt(data['expires_in'])
    data['expire_time'] = expire_time

    window.localStorage.setItem('token_'+username,JSON.stringify(data))
    return data['access_token']
  }).catch(function (error) { // 请求失败处理
    console.log(error)
    return null
  })
}



/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen();
  } else {
    reqFullScreen();
  }
};
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
  function listen() {
    callback()
  }
  document.addEventListener("fullscreenchange", function () {
    listen();
  });
  document.addEventListener("mozfullscreenchange", function () {
    listen();
  });
  document.addEventListener("webkitfullscreenchange", function () {
    listen();
  });
  document.addEventListener("msfullscreenchange", function () {
    listen();
  });
};
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen
}

// /**
//  * 浏览器全屏
//  */
// export const reqFullScreen = () => {
//   if (document.documentElement.requestFullScreen) {
//     document.documentElement.requestFullScreen();
//   } else if (document.documentElement.webkitRequestFullScreen) {
//     document.documentElement.webkitRequestFullScreen();
//   } else if (document.documentElement.mozRequestFullScreen) {
//     document.documentElement.mozRequestFullScreen();
//   }
// };
// /**
//  * 浏览器退出全屏
//  */
// export const exitFullScreen = () => {
//   if (document.documentElement.requestFullScreen) {
//     document.exitFullScreen();
//   } else if (document.documentElement.webkitRequestFullScreen) {
//     document.webkitCancelFullScreen();
//   } else if (document.documentElement.mozRequestFullScreen) {
//     document.mozCancelFullScreen();
//   }
// };
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length != 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id == id) {
          return menu[i]
        } else {
          if (menu[i].children[j].children.length != 0) {
            return findParent(menu[i].children[j].children, id)
          }
        }
      }
    }
  }
}

/**
 * 动态插入css
 */

export const loadStyle = url => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
  let result = true
  Object.keys(a).forEach(ele => {
    const type = typeof (a[ele])
    if (type === 'string' && a[ele] !== b[ele]) result = false
    else if (type === 'object' && JSON.stringify(a[ele]) !== JSON.stringify(b[ele])) result = false
  })
  return result
}
/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
  let result = ''
  if (validatenull(dic)) return value
  if (typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean') {
    let index = 0
    index = findArray(dic, value)
    if (index != -1) {
      result = dic[index].label
    } else {
      result = value
    }
  } else if (value instanceof Array) {
    result = []
    let index = 0
    value.forEach(ele => {
      index = findArray(dic, ele)
      if (index != -1) {
        result.push(dic[index].label)
      } else {
        result.push(value)
      }
    })
    result = result.toString()
  }
  return result
}
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value == value) {
      return i
    }
  }
  return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len || 4)
  if (date) random = random + Date.now()
  return random
}
