<template>
    <div class="home">
        <img id="bg" src="src/assets/img/index-bg.jpg" :height="fullHeight">
    </div>
</template>

<script>
    import { Toast } from 'vant';
    export default {
        data() {
            return {

            };
        },
        name: 'Home',
        components: {
        },
        mounted() {

        },
        computed:{

        },
        methods:{

        }

    }
</script>
<style>
    .home{
        overflow: hidden;
    }

    #bg {
        transform: scale(1.2);
        position: fixed;
        filter:blur(5px);

    }
</style>
