<template>
    <div class="setting-box">
        <van-nav-bar
                title="设置"
                left-text=""
                right-text=""
                @click-left=""
                @click-right=""
        >
        </van-nav-bar>
        <van-cell-group>
            <van-cell title="账号" :value="username"/>
            <van-cell title="手机号" :value="phone"/>
            <van-cell title="创建时间" :value="createTime"/>
<!--            <van-cell title="头像" :value="avatar"/>-->
            <van-cell title="修改密码"  @click="changePassword"><van-icon class="add-btn" name="arrow"/></van-cell>
            <van-cell title="退出登录"  @click="logout"><van-icon class="add-btn" name="arrow"/></van-cell>
        </van-cell-group>
        <van-cell-group>
            <van-cell title="我的托管" is-link :to="{path:'/escrow'}"></van-cell>
        </van-cell-group>


        <Tabbar></Tabbar>
    </div>
</template>

<script>
    import { Toast } from 'vant'
    import {encryptXD, getToken, getTokenFromLocalStorage} from "../util/util"
    import axios from "axios"
    import router from "../router"
    import Tabbar from "../components/Tabbar"
    export default {
        name: "Setting",
        data() {
            return {
                username: localStorage.getItem("currentUser"),
                originPassword:'',
                newPassword:'',
                newPasswordConfirm:'',
                avatar:'',
                createTime:'',
                phone:'',
            };
        },
        components: {
            Tabbar
        },
        mounted() {
            this.getUserInfo()
            let token = getTokenFromLocalStorage(this.username)
            var websocket = null;
            if('WebSocket' in window){
                websocket = new WebSocket("ws://192.168.1.114:9999/dkv1/websocket/testname?access_token="+token);
            }

            websocket.onopen = function(){
                console.log("连接成功");

                // websocket.send(token)
            }

            websocket.onclose = function(){
                console.log("退出连接");
            }

            websocket.onmessage = function (event){
                console.log("收到消息"+event.data);
            }

            websocket.onerror = function(){
                console.log("连接出错");
            }

            window.onbeforeunload = function () {
                websocket.close(num);
            }
        },
        methods: {
            getUserInfo(){
                let token = getTokenFromLocalStorage(this.username)
                axios({
                    method: 'get',
                    url: '/admin/user/info',
                    headers: {'Authorization': 'Bearer ' + token}
                }).then(resp => {
                    let data = resp.data.data.sysUser
                    console.log(data)
                    this.avatar = data.avatar
                    this.createTime = data.createTime
                    this.phone = data.phone
                })
            },
            changePassword(){
                Toast.success("修改密码")
            },
            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
            logout(){
              localStorage.clear()
                router.push("/login")
            },
            onSubmit(values) {
                axios({
                    url: '/auth/oauth/token',
                    method: 'post',
                    params: {
                        'username': this.username,
                        'password': encryptXD(this.password),
                        'grant_type': 'password',
                        'scope': 'server'
                    },
                    headers: {
                        isToken: false,
                        'Authorization': 'Basic dGVzdDp0ZXN0'
                    }
                }).then(response => {
                    // console.info(response.data)
                    var data = response.data
                    let now = new Date().getTime()
                    let expire_time = now + parseInt(data['expires_in'])
                    data['expire_time'] = expire_time

                    localStorage.setItem('token_' + this.username, JSON.stringify(data))
                    localStorage.setItem('currentUser', this.username)
                    Toast.success("登录成功")
                    router.push('/')
                }).catch(function (error) { // 请求失败处理
                    console.log(error)
                    Toast.fail("登录失败")
                })
            }
        },
    }
</script>

<style scoped>
    #logo{
        width: 40vw;
        margin-left: 30vw;
        margin-top: 30vw;
    }
    #form {
        margin-top: 10vw;
        margin-left: 10vw;
        width: 80vw;
    }
    .van-doc-demo-block__card {
        margin: 12px 12px 0;
        overflow: hidden;
        border-radius: 8px;
    }
</style>