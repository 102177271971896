<template>
    <van-nav-bar
            title="属 Genus"
            left-text=""
            right-text=""
            @click-left="onClickLeft"
            @click-right="onClickRight"
            left-arrow
    >
<!--        <template #left>-->
<!--            <van-icon name="wap-home-o"  size="18"/>-->
<!--        </template>-->
        <template #right>
            <van-icon name="search" size="18" />
        </template>
    </van-nav-bar>


    <form action="/">
        <van-search
                v-show="showSearchBox"
                v-model="searchKey"
                placeholder="请输入搜索关键词"
                @search="getList(true)"
        />
    </form>


    <div class="first-char-box">
        <span  class="char" v-for="item in allChars" @click="changeFirstChar(item)" :class="{'char-select': item === firstChar}">
            {{ item }}
        </span>
    </div>

    <van-divider />
    <div class="alias-btn" @click="toggleAlias">别名</div>


    <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
    >
        <van-cell
                v-for="item in records"
                :data-id="item.hid"
                :class="{isPrimary: item.primaryHid === ''}"
                v-show="item.primaryHid === '' || (item.primaryHid !== '' && showAlias)"
                :to="{path:'/species',query:{genusId:item.hid,genus:item.genus}}"
        >
            <van-row>
                <van-col span="8" class="avatar-box">

                    <van-image v-if="undefined !== item.avatar && '' !== item.avatar"
                               width="20vw"
                               height="20vw"
                               fit="cover"
                               :src="'https://drartisan.cdn.bcebos.com/species/'+item.avatar+'?x-bce-process=style/avatar'"
                    />
                    <van-image
                            v-else
                            width="20vw"
                            height="20vw"
                            fit="cover"
                            src="https://drartisan.cdn.bcebos.com/common/default.png"
                    />



                </van-col>
                <van-col span="16" class="info-box">
                    <van-row>
                        <van-col class="info-genus" span="24">{{item.genus}} {{item.cnGenus}}</van-col>
                    </van-row>
                    <van-row>
                        <van-col class="info-author" span="24">{{item.author}}</van-col>
                    </van-row>
                    <van-row v-if="item.primaryHid !== ''">
                        <van-col class="info-primary" span="24">== {{item.primaryGenus}} {{item.primaryAuthor}}</van-col>
                    </van-row>
                    <van-row>
                        <van-col class="info-visit-cnt" span="16">
                            <van-tag v-if="item.primaryHid === ''" class="genus-tag" plain type="success">
                                认证
                            </van-tag>
                            <van-tag v-else class="genus-tag" plain type="primary">
                                别名
                            </van-tag>
<!--                            <van-tag class="genus-tag" plain type="success">-->
<!--                                <van-icon name="fire-o" size="18"/>{{item.visitCnt}}-->
<!--                            </van-tag>-->
                        </van-col>
                    </van-row>
                </van-col>
            </van-row>

        </van-cell>
    </van-list>
</template>

<script>
    // import { reactive } from 'vue';
    import axios from 'axios'
    // import SearchCharacter from "../components/SearchCharacter";
    import {getTokenFromLocalStorage} from "../util/util";
    import request from '../util/axios'
    import router from "../router";
    export default {
        name: "Genus",
        // components: {SearchCharacter},
        data(){
            return {
                records: [],
                loading: true,
                finished: false,
                allChars:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
                firstChar: '',
                searchKey: '',
                page:0,
                size:20,
                showAlias: false,
                showSearchBox: false
            }
        },
        // watch:{
        //    firstChar: {
        //        handler(newVal, oldVal) {
        //            console.log(newVal,oldVal)
        //        },
        //        immediate:true,
        //    }
        // },
        mounted() {
            // this.getList()
        },
        methods:{
            onClickRight(){
                this.showSearchBox = ! this.showSearchBox
            },
            onClickLeft(){
                router.back()
            },
            toggleAlias(){
                this.showAlias = !this.showAlias
                console.log(this.showAlias)
            },
            onCancel(){
                console.log('cancel')
            },
            changeFirstChar(char){
                if (this.firstChar === char){
                    this.firstChar = ''
                }else{
                    this.firstChar = char
                }

                this.getList(true)
            },
            getList(reset){
                this.loading = true


                if (reset){
                    this.page=1
                    this.finished = false
                }

                request({
                    url: 'clog/genus/search',
                    method: 'get',
                    params: {searchKey:this.searchKey,
                        firstChar:this.firstChar,
                        page:this.page,
                        size:this.size}
                }).then(resp => {
                    if (reset){
                        this.records = []
                    }
                    console.info(resp.data)
                    resp.data['data']['records'].forEach((item,index) => {
                        this.records.push(item)
                    })
                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (this.page >= resp.data['data']['pages']) {
                        this.finished = true;
                    }
                })

                // let token = getTokenFromLocalStorage('token_'+ localStorage.getItem('currentUser'))
                // console.log(token)
                // axios({
                //     method: 'get',
                //     url: 'clog/genus/search',
                //     params: {searchKey:'',firstChar:'','page':this.page,'size':this.size},
                //     headers: {'Authorization': 'Bearer ' + token}
                // }).then(resp => {
                //     console.info(resp.data)
                //     resp.data['data']['records'].forEach((item,index) => {
                //         this.genera.push(item)
                //     })
                //     // 加载状态结束
                //     this.loading = false;
                //
                //     // 数据全部加载完成
                //     if (this.page >= resp.data['data']['pages']) {
                //         this.finished = true;
                //     }
                // })
            },
            onLoad(){
                this.page +=1
                this.getList(false)
            }
        }
    }
</script>

<style scoped>
    .first-char-box{
        width: 90%;
        text-align: center;
        margin-left: 5%;
        height: 10rem;
    }

    .char {
        display: block;
        float: left;
        margin: 0.3rem;
        width: 2rem;
        height: 2rem;
        background-color: #333333;
        color: #FFFFFF;
        text-align: center;
        line-height: 2rem;
    }


    .char-select {
        background-color: #a3a3a3 !important;
        color: #333333 !important;
    }

    .isPrimary {
        /*background-color: rgba(117, 138, 153, 0.44);*/
    }

    .alias-btn {
        position: fixed;
        right: 10vw;
        bottom: 20vw;
        z-index: 999;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        border-radius: 1.5rem;
        background-color: rgba(221, 221, 221, 0.43);
        color: #ffffff;
    }
</style>
