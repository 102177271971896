import {serialize} from './util'
import NProgress from 'nprogress'
import router from "../router/index"
import 'nprogress/nprogress.css'
import qs from 'qs'
import axios from 'axios'

axios.defaults.timeout = 30000
axios.defaults.baseURL = 'https://drartisan.com/'

// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
  showSpinner: false
})

// HTTPrequest拦截
axios.interceptors.request.use(config => {
  NProgress.start() // start progress bar
  console.log(config)
  let isToken = (config.headers || {}).isToken === false
  if ((config['url'] === 'clog/genus/search' || config['url'] === 'clog/species/search')
      && config['params']['page']<3){
    // 页数小于3时不需要token
    isToken = true
  }
  let token = localStorage.getItem("token_" + localStorage.getItem("currentUser"))//store.getters.access_token
  console.log(token)

  if (!isToken && token) {
    console.log('add token .........')
    let t = JSON.parse(token)['token']
    console.log(t)
    config.headers['Authorization'] = 'Bearer ' + t// token
  }

  // headers中配置serialize为true开启序列化
  if (config.methods === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  // 处理get 请求的数组 springmvc 可以处理
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})


// HTTPresponse拦截
axios.interceptors.response.use(res => {
  NProgress.done()
  const status = Number(res.status) || 200
  const message = res.data.msg //|| errorCode[status] || errorCode['default']
  if (status === 401 || status === 403) {
      router.push({path: '/login'})
    return
  }

  if (status !== 200 || res.data.code === 1) {
    // Message({
    //   message: message,
    //   type: 'error'
    // })
    console.log('ERROR MESSAGE:',message)
    return Promise.reject(message)
  }

  return res
}, error => {
  NProgress.done()
  console.log('ERROR--------------------------------')
  router.push({path: '/login'})
  return Promise.reject(error)
})

export default axios
