<template>
    <div class="login-box">
        <img id="logo" src="src/assets/img/logo.png"/>
        <van-form id="form" validate-first @submit="onSubmit" @failed="onFailed">
            <van-field
                    v-model="username"
                    name="username"
                    label="用户名"
                    placeholder="用户名"
                    :rules="[{ required: true, message: '请填写用户名' }]"
                    />
            <van-field
                    v-model="password"
                    name="password"
                    label="密码"
                    placeholder="密码"
                    type="password"
                    :rules="[{ required: true, message: '请填写密码' }]"
            />
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    import {encryptXD, getToken} from "../util/util";
    import axios from "axios";
    import router from "../router";
    export default {
        name: "Login.vue",
        data() {
            return {
                username: 'admin',
                password: '123456',
            };
        },
        methods: {
            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },
            onSubmit(values) {
                axios({
                    url: '/clog/cuser/login/normal',
                    method: 'post',
                    data: {
                        username: this.username,
                        password: this.password
                    },
                    headers: {
                        isToken: false,
                        'Content-Type': 'application/json;'
                    }
                }).then(response => {
                    var data = new Object()
                    data['token'] = response.headers['authorization']
                    let now = new Date().getTime()
                    let expire_time = now + 3600
                    data['expire_time'] = expire_time

                    localStorage.setItem('token_' + this.username, JSON.stringify(data))
                    localStorage.setItem('currentUser', this.username)
                    Toast.success("登录成功")
                    router.push('/Genus')
                }).catch(function (error) { // 请求失败处理
                    console.log(error)
                    Toast.fail("登录失败")
                })
            }
        },
    }
</script>

<style scoped>
    #logo{
        width: 40vw;
        margin-left: 30vw;
        margin-top: 30vw;
    }
    #form {
        margin-top: 10vw;
        margin-left: 10vw;
        width: 80vw;
    }
</style>
