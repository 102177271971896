<template>
    <div id="nav">
        <van-nav-bar
                title="Dianke"
                left-text=""
                right-text=""
                @click-left="onClickLeft"
                @click-right="onClickRight"
        />


    </div>
</template>

<script>

    export default {
        name: "NavBar",
        props: {
        },
        data(){
            return{
                showAddPopup: false
            }
        },
        methods: {
            onClickLeft() {
                console.log("click left")

            },
            onClickRight() {
                this.showAddPopup = true
            }
        }
    }
</script>

<style scoped>

</style>