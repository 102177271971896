<template>
    <div class="event">

        <van-nav-bar
                title="事件管理"
                left-text=""
                right-text=""
                @click-left="goBack"
                @click-right=""
                left-arrow
        >
        </van-nav-bar>

<!--        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" height="300">-->
<!--            <van-swipe-item>1</van-swipe-item>-->
<!--            <van-swipe-item>2</van-swipe-item>-->
<!--            <van-swipe-item>3</van-swipe-item>-->
<!--            <van-swipe-item>4</van-swipe-item>-->
<!--        </van-swipe>-->

        <van-list
                v-model:loading="state.loading"
                :finished="state.finished"
                finished-text="没有更多了"
                @load="getList"
        >
            <van-cell v-for="item in state.list" :key="item" :title="item.status" @click="showPopup(item)">
                {{item.eventId.slice(0,20)+'...'}}
                <br>
                {{item.recordTime}}
            </van-cell>
        </van-list>



       <Tabbar></Tabbar>
        <van-popup id="detail-popub" v-model:show="showDetail" round closeable>
            <van-swipe v-if="eventDetail.imgList !=null" class="my-swipe" :autoplay="3000" indicator-color="gary">
                <van-swipe-item v-for="item in eventDetail.imgList.split(',')" :key="item">
                    <van-image fit="contain" :src="'http://192.168.1.114:8080'+item.slice(1)"/>
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator"></div>
                </template>
            </van-swipe>
            <div class="detail">
                事件ID：{{eventDetail.eventId}}<br>
                设备ID：{{eventDetail.deviceId}}<br>
                所有者ID：{{eventDetail.ownerId}}<br>
                部门ID：{{eventDetail.deptId}}<br>
                类型：{{eventDetail.eventType}}<br>
                状态：{{eventDetail.status}}<br>
                记录时间：{{eventDetail.recordTime}}<br>
                接收时间：{{eventDetail.receiveTime}}<br>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import { Toast,List,Popup,Swipe,SwipeItem } from 'vant'
    import axios from "axios"
    import {getTokenFromLocalStorage} from "../util/util"
    import Tabbar from "../components/Tabbar"
    import router from "../router";
    export default {
        data() {
            return {
                username:localStorage.getItem("currentUser"),
                events: [],
                eventDetail:{},
                showDetail: false,
                state:{
                    list: [],
                    loading: false,
                    finished: false,
                }
            };
        },
        name: 'Event',
        components: {
            Tabbar
        },
        computed:{
        },
        methods:{
            goBack() {
                window.history.go(-1)
            },
            clickLeft(){
                console.log("Home click left")
            },
            addDevice(){
                Toast.success("添加设备")
            },
            getList(){
                let deviceId = this.$route.query.deviceId
                var url = '/dkv1/event/page'
                var params = {current:1,size:20}

                if (deviceId != null){
                    url = '/dkv1/event/pageByDeviceId'
                    params.deviceId = deviceId
                }
                let token = getTokenFromLocalStorage(this.username)
                // console.log(token)
                axios({
                    method: 'get',
                    url: url,
                    params: params,
                    headers: {'Authorization': 'Bearer ' + token}
                }).then(resp => {
                    if(resp.data.code == 0) {
                        this.state.list = resp.data['data']['records']
                        // 加载状态结束
                        this.state.loading = false
                        if (resp.data['total'] == resp.data['pages']) {
                            this.state.finished = true
                        }
                    }else{
                        Toast.fail("加载失败 "+resp.data.msg)
                    }
                }).catch(error =>{
                    console.log(error)
                    let statusCode = error.response.status
                    if (statusCode>=400 && statusCode<500){
                        router.push('/login')
                    }else if (statusCode>=500){
                        Toast.fail("服务器错误")
                    }


                })
            },
            showPopup(event){
                console.log(event)
                this.eventDetail = event
                this.showDetail = true
            }
        }

    }
</script>
<style>
    .home-header {
        width: 100%;
        height: 20vw;
        background-color: #42b983;
    }
    span.username {
        width: 30vw;
        display: block;
        float: left;
        line-height: 20vw;
        margin-left: 5vw;
        color: #FFFFFF;
    }
    .add-btn-box {
        width: 10vw;
        height: 20vw;
        line-height: 20vw;
        float: right;
        color: #f2f3f5;
        font-size: 8vw;
        margin-right: 5vw;
    }

    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: #39a9ed;
    }
    .device-img {
        width: 30vw;
    }

    #detail-popub{
        text-align: left;
        width: 90vw;
        padding: 15vw 0vw 15vw 0vw;
    }
    #detail-popub img{
        width: 100%;
        /*margin-left: 10%;*/
    }
    #detail-popub .detail {
        margin-top: 5vw;
        width: 80%;
        margin-left: 10%;
    }
    .my-swipe{
        height: 67vw;
    }
    .my-swipe .van-swipe-item {
        background-color: #FFFFFF;
    }
    .van-swipe__indicators{
        display: none;
    }

</style>
