<template>
    <van-tabbar route safe-area-inset-bottom>
        <van-tabbar-item icon="home-o" replace to="/">主页</van-tabbar-item>
        <van-tabbar-item icon="bulb-o" replace to="/event">事件</van-tabbar-item>
<!--        <van-tabbar-item icon="friends-o" replace to="/blogs">博客</van-tabbar-item>-->
        <van-tabbar-item icon="setting-o" replace to="/setting">设置</van-tabbar-item>
    </van-tabbar>
</template>

<script>
    export default {
        name: "Tabbar"
    }
</script>

<style scoped>

</style>