<template>
    <div class="page">
        <van-nav-bar
            title=""
            left-text=""
            right-text=""
            @click-left="onClickLeft"
            @click-right="onClickRight"
            left-arrow
    >
            <template #title>
                {{title}}
            </template>
<!--            <template #left>-->
<!--                <van-icon name="wap-home-o" size="18"/>-->
<!--            </template>-->
            <template #right>
                <van-icon name="wap-home-o" size="18"/>
            </template>
        </van-nav-bar>



        <div class="detail" v-if="detail !== undefined">

            <div class="box" v-if="undefined !== detail.main_photo">
                <van-image id="main-photo"
                        width="100%"
                        fit="cover"
                        :src="'https://drartisan.cdn.bcebos.com/species/'+detail.main_photo.plant_main_photo_url"
                />
                <div class="photo-info">
                    <span class="photo-name">{{detail.main_photo.plant_main_photo_name}}</span>
                    <span class="photo-author">-- {{detail.main_photo.plant_main_photo_author}}摄</span>
                </div>
                <div class="photo-desc" v-if="undefined !== main_photo && undefined !== detail.main_photo.plant_main_photo_desc">
                    {{detail.main_photo.plant_main_photo_desc}}
                </div>
            </div>

            <van-devider/>

            <div class="box main-info">
                <div class="species-name">{{detail.species_name}} <span class="species-author">{{detail.species_author}}</span></div>

                <div class="species-subheader">{{detail.plant_main_subheader}}</div>
            </div>

            <!--    Accepted-->
            <div class="box" v-if="detail.accepted !== undefined">
                <div class="content">
                    <div class="content-sub">
                        <router-link class="acctpted-species-name" :to="{path:'/speciesDetail', query:{speciesId:species.acceptedHid}}">
                            <van-tag class="genus-tag" plain type="success">认证</van-tag>
                            {{detail.accepted.accepted_scientific_name}}
                            <span class="acctpted-author">{{detail.accepted.accepted_scientific_author}}</span>
                        </router-link>

                        <div class="acctpted-species-from">{{detail.accepted.accepted_scientific_from}}</div>
                        <div class="acctpted-species-other" v-if="detail.accepted.accepted_scientific_other_info !== undefined && detail.accepted.accepted_scientific_other_info.length > 0">
                            <div class="content-sub-content-2"
                                 v-for="item in detail.accepted.accepted_scientific_other_info">{{item}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <van-divider/>
            <!--    Photo List-->
            <div class="box" v-if="detail.photo_list !== undefined && detail.photo_list.length > 0">
                <van-swipe class="photo-list-swipe" :autoplay="5000" indicator-color="white" @change="onSwipeChange">


<!--                    将主图加入轮播-->
<!--                    <van-swipe-item v-if="undefined !== detail.main_photo">-->
<!--                        <img class="photo-list-item" :src="'https://drartisan.cdn.bcebos.com/species/'+detail.main_photo.plant_main_photo_url"/>-->
<!--                        <div class="photo-list-info">-->
<!--                            <span class="photo-list-info-name">名称:{{detail.main_photo.plant_main_photo_name}}</span><br>-->
<!--                            <span class="photo-list-info-author">&#45;&#45; {{detail.main_photo.plant_main_photo_author}}摄</span>-->
<!--                            <span class="photo-list-info-desc" v-if="undefined !== main_photo && undefined !== detail.main_photo.plant_main_photo_desc">-->
<!--                                {{detail.main_photo.plant_main_photo_desc}}-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </van-swipe-item>-->



                    <van-swipe-item v-for="item in detail.photo_list">
                        <img class="photo-list-item" :src="'https://drartisan.cdn.bcebos.com/species/'+item.img"/>
                        <div class="photo-list-info">
                            <span class="photo-list-info-name" v-if="undefined != item.name">名称:{{item.name}} </span>
                            <span class="photo-list-info-name" v-else>名称:{{detail.species_name}} </span><br>
                            <span class="photo-list-info-synonyms" v-if="undefined != item.synonyms">别名:{{item.synonyms}}</span>
                            <span class="photo-list-info-author">-- {{item.photo_author}}摄</span>
                        </div>
                    </van-swipe-item>
                    <template #indicator>
                        <div class="custom-indicator">{{ current + 1 }}/{{detail.photo_list.length}}</div>
                    </template>
                </van-swipe>

            </div>





            <van-tabs v-model="active" type="card" sticky animated>
                <van-tab class="tab-box" title="描述">
                    <!--    Description-->
                    <div class="box"  v-if="tabs.hasDescription">
                        <div class="content">
                            <div class="content-sub" v-for="(v,k) in detail.description">
                                <div class="content-sub-title">{{k}}</div>
                                <div class="content-sub-content">{{v}}</div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-if="! tabs.hasDescription" description="暂无信息"/>
                </van-tab>

                <van-tab class="tab-box" title="别名">
                    <!--    Complete Synonyms List-->
                    <div class="box"  v-if="tabs.hasSynonyms">
                        <div class="content">
                            <div class="content-sub" v-for="item in detail.complete_synonyms_list">
                                <router-link class="level1" :to="{path:'/speciesDetail',query:{speciesId:item.level1.species_id}}">●{{item.level1.name}}</router-link>
                                <div class="content-sub-content level2-box">
                                    <router-link class="level2"  v-for="l2 in item.level2" :to="{path:'/speciesDetail',query:{speciesId:l2.species_id}}">○{{l2.name}}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-if="! tabs.hasSynonyms" description="暂无信息"/>
                </van-tab>

                <van-tab class="tab-box" title="产地">
                    <!--    Origin Habbit-->
                    <div class="box" v-if="tabs.hasOriginHabbit">
<!--                        <div class="title">origin_habbit</div>-->
                        <div class="content">
                            <div class="content-sub" v-if="undefined != detail.origin_habbit['Altitude range']">
                                <div class="content-sub-title">Altitude range</div>
                                <div class="content-sub-content">{{detail.origin_habbit['Altitude range']}}</div>
                            </div>
                            <div class="content-sub" v-if="undefined != detail.origin_habbit.Habitat">
                                <div class="content-sub-title">Habitat</div>
                                <div class="content-sub-content">{{detail.origin_habbit.Habitat}}</div>
                            </div>
                            <div class="content-sub" v-if="undefined != detail.origin_habbit['Origin and Habitat']">
                                <div class="content-sub-title">Origin and Habitat</div>
                                <div class="content-sub-content">{{detail.origin_habbit['Origin and Habitat']}}</div>
                            </div>

                        </div>
                    </div>
                    <van-empty v-if="! tabs.hasOriginHabbit" description="暂无信息"/>
                </van-tab>

                <van-tab class="tab-box" title="亚种">
                    <!--    Sub Clut Desc-->
                    <div class="box" v-if="tabs.hasSubCultDesc">
                        <div class="content">{{detail.sub_cult_desc}}</div>
                    </div>
                    <!--    Sub Clut List-->
                    <div class="box" v-if="tabs.hasSubCultList">

                            <van-cell
                                    v-for="item in detail.sub_cult_list"
                                    :to="{path:'/speciesDetail',query:{speciesId:item.species_id,species:item.sub_name}}"
                            >
                                <van-row>
                                    <van-col span="8" class="avatar-box">

                                        <van-image
                                                v-if="undefined != item.sub_img && item.sub_img.length>0"
                                                width="20vw"
                                                height="20vw"
                                                fit="cover"
                                                :src="'https://drartisan.cdn.bcebos.com/species/'+item.sub_img[0]+'?x-bce-process=style/avatar'"
                                        />
                                        <van-image
                                                v-else
                                                width="20vw"
                                                height="20vw"
                                                fit="cover"
                                                src="https://drartisan.cdn.bcebos.com/common/default.png"
                                        />


                                    </van-col>
                                    <van-col span="16" class="info-box">
                                        <van-row>
                                            <van-col class="info-species" span="24">{{item.sub_name}}</van-col>
                                        </van-row>
                                        <van-row>
                                            <van-col class="info-visit-cnt" span="24">
                                                {{item.desc}}
                                            </van-col>
                                        </van-row>
                                    </van-col>
                                </van-row>

                            </van-cell>
                    </div>
                    <van-empty v-if="! (tabs.hasSubCultDesc || tabs.hasSubCultDesc)" description="暂无信息"/>
                </van-tab>

                <van-tab class="tab-box" title="栽培">
                    <!--    Cultivtion Propagation-->
                    <div class="box" v-if="tabs.hasCultivationPropagation">
<!--                        <div class="title">cultivation_propagation</div>-->
                        <div class="content">
                            <div class="content-sub" v-for="(v,k) in detail.cultivation_propagation">
                                <div class="content-sub-title">{{k}}</div>
                                <div class="content-sub-content">{{v}}</div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-if="! tabs.hasCultivationPropagation" description="暂无信息"/>
                </van-tab>

<!--                <van-tab class="tab-box" title="参考">-->
<!--                    &lt;!&ndash;    Bibliography&ndash;&gt;-->
<!--                    <div class="box"  v-if="tabs.hasBibliography">-->
<!--                        <div class="title">bibliography</div>-->
<!--                        <div class="content">{{detail.bibliography}}</div>-->
<!--                    </div>-->
<!--                    <van-empty v-if="! tabs.hasBibliography" description="暂无信息"/>-->
<!--                </van-tab>-->

                <van-tab class="tab-box" title="俗称">
                    <!--    Common Names-->
                    <div class="box" v-if="tabs.hasCommonName">
<!--                        <div class="title">common_names</div>-->
                        <div class="content">
                            <div class="content-sub" v-for="(v,k) in detail.common_names">
                                <div class="content-sub-title">{{k}}</div>
                                <div class="content-sub-content">{{v}}</div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-if="! tabs.hasCommonName" description="暂无信息"/>
                </van-tab>
            </van-tabs>

        </div>
    </div>
</template>

<script>
    import request from '../util/axios'
    import router from "../router";
    import StatChart from "../components/StatChart";

    export default {
        name: "SpeciesDetail",
        components: {StatChart},
        data() {
            return {
                active: 0,
                species:undefined,
                detail: undefined,
                tabs:{
                    hasDescription: false,
                    hasSynonyms:  false,
                    hasOriginHabbit:  false,
                    hasSubCultDesc:  false,
                    hasSubCultList:  false,
                    hasCultivationPropagation: false,
                    hasBibliography:  false,
                    hasCommonName:  false
                },
                speciesId: this.$route.query.speciesId,
                title: this.$route.query.species,
                current: 0,
                // meta: {
                //     title: '首页'
                // }
            }
        },
        watch:{
            $route: {
                handler() {
                    if (this.speciesId !== this.$route.query.speciesId){
                        this.speciesId = this.$route.query.speciesId;
                        this.getDetail();
                    }
                    //深度监听，同时也可监听到param参数变化
                },
                deep: true,
            }
        },
        computed() {

        },
        mounted() {
            this.getDetail()
        },
        methods: {
            onClickRight() {
                this.showSearchBox = !this.showSearchBox
                router.push("/genus")
            },
            onClickLeft() {
                router.back()
            },
            getDetail() {
                console.log('开始加载。。。')
                // console.log("out this",this)

                request({
                    url: 'clog/speciesDetail/' + this.speciesId,
                    method: 'get',
                }).then(resp => {
                    // console.log("in this",this)
                    console.info(resp.data.data)
                    let data = resp.data.data
                    this.species = data.species
                    this.detail = data.detail
                    this.tabs.hasDescription = data.description !== undefined
                    this.tabs.hasDescription = this.detail !==undefined && this.detail.description !== undefined
                    this.tabs.hasSynonyms =  this.detail !==undefined && this.detail.complete_synonyms_list !== undefined && this.detail.complete_synonyms_list.length>0
                    this.tabs.hasOriginHabbit =  this.detail !==undefined && this.detail.origin_habbit !== undefined
                    this.tabs.hasSubCultDesc =  this.detail !==undefined && this.detail.sub_cult_desc !== undefined
                    this.tabs.hasSubCultList =  this.detail !==undefined && this.detail.sub_cult_list !== undefined && this.detail.sub_cult_list.length>0
                    this.tabs.hasCultivationPropagation =  this.detail !==undefined && this.detail.cultivation_propagation !== undefined
                    this.tabs.hasBibliography =  this.detail !==undefined && this.detail.bibliography !== undefined
                    this.tabs.hasCommonName =   this.detail !==undefined && this.detail.common_names !== undefined

                    console.log(this.tabs)

                })


            },
            onSwipeChange(index) {
                this.current = index;
            },
        }
    }
</script>

<style scoped>
    .first-char-box {
        width: 90%;
        text-align: center;
        margin-left: 5%;
        height: 10rem;
    }

    .char {
        display: block;
        float: left;
        margin: 0.3rem;
        width: 2rem;
        height: 2rem;
        background-color: #333333;
        color: #FFFFFF;
        text-align: center;
        line-height: 2rem;
    }


    .char-select {
        background-color: #a3a3a3 !important;
        color: #333333 !important;
    }

    .isPrimary {
        background-color: rgba(66, 185, 131, 0.33);
    }

    .alias-btn {
        position: fixed;
        right: 10vw;
        bottom: 20vw;
        z-index: 999;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        border-radius: 1.5rem;
        background-color: rgba(221, 221, 221, 0.43);
        color: #ffffff;
    }


    .info-species {
        font-weight: bold;
        font-style: italic;
    }

    .box {
        width: 90%;
        margin-left: 5%;
        height: auto;
        margin-bottom: 1rem;
        /*background-color: aliceblue;*/
        /*word-break:break-all;*/
    }

    .title {
        font-weight: bold;
        text-align: left;
        margin-bottom: 0.5rem;

    }

    .title::before {
        content: '【';
    }

    .title::after {
        content: '】';
    }

    .content {
        display: block;
        margin-bottom: 0.5rem;
    }

    .content-sub {
        width: 100%;
        height: auto;
        margin-bottom: 0.5rem;
        /*background-color: #bbbbbb;*/
    }

    .content-sub-title {
        font-weight: bold;
    }


    /*主信息*/
    .main-info{

    }

    .species-name{
        font-weight: bold;
        font-size: 1.1rem;
        font-style: italic;
    }

    .species-author {
        font-weight: normal;
        font-size: 1rem;
        font-style: italic;
    }

    .species-subheader{
        font-size: 0.8rem;
        color: #333333;
    }

    .acctpted-species-name{
    }
    .acctpted-species-from{
        font-size: 0.9rem;
    }
    .acctpted-species-other{
        font-size: 0.8rem;
        color: #333333;
    }


    /*首页主图*/
    .photo-info {
        font-size: 0.8rem;
        color: grey;
        height: auto;
    }

    .photo-author {
        float: right;
    }

    .photo-desc {
        width: 100%;
        float: none;
        clear: both;
        font-size: 1rem;
        color: #000000;
        margin-top: 0.5rem;
    }


    /*图片列表*/
    .custom-indicator {
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 2px 5px;
        font-size: 12px;
        color: #333333;
        background: rgba(0, 0, 0, 0.1);
    }

    .photo-list-swipe {
        position: relative;
    }

    .photo-list-item {
        width: 100%;
    }

    .photo-list-info {
        width: 98%;
        padding-left: 2%;
        height: auto;
        position: absolute;
        bottom: 0.2rem;
        left: 0;
        z-index: 999;
        font-size: 0.8rem;
        color: #333333;
        background-color: rgba(198, 198, 198, 0.43);

    }

    .tab-box {
        min-height: 100vw;
        padding-top: 1rem;
    }


    .level1{
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.9rem;
        color: #111111;
    }

    .level2-box{
        text-indent: 1rem;
        margin-bottom: 1rem;

    }

    .level2{
        color: #333333;
        font-size: 0.8rem;
        display: block;
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
    }

</style>
